import React, { useState, useContext, useMemo } from "react"


import { ProviderContext } from "../../App";

const Settings = (props) => {

    const users = props.Users
    const {profile,env} = useContext(ProviderContext)
    const [roleValue, isRoleValue] = useState('none')
    const [roleValueOG, isRoleValueOG] = useState('none')
    const [roleUser, isRoleUser] = useState('none')
    const [newselection,isNewSelection] = useState(false)
    const [departments, isDepartments] = useState([])
    

    const [partnumber, setPartNumber] = useState('')
    const [partdesc, setPartDesc] = useState('')
    const [searchword, setSearchWord] = useState('')
    const [approveList, setApproveList] = useState({});
    
    console.log('users ===> ',users)
    const changeRole = async () => {
        let arr = []
        console.log('updating role of ' + roleUser + ' as ' + roleValue)
        
        /* eslint-disable */
        $('.pills.active').each(function(index,elem){
            console.log($(elem).text().substring(0,2))
            arr.push($(elem).text().substring(0,2))
        })  

        const departments = arr.join(',')
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/changeRole?ID=${roleUser}&Permission=${roleValue}&Departments=${departments}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('role updated ==> ', JSON.parse(result))
            let res = JSON.parse(result)
          
            isRoleValueOG(roleValue)
            isNewSelection(false)
            console.log(res)
            alert(res)
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    const createPart = async () => {

        let data = {
            partNum: partnumber,
            searchWord: searchword,
            partDesc: partdesc
        }

        let stringifiedData = JSON.stringify(data)

        console.log('to create ===> ', data, stringifiedData)
         
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: stringifiedData
        };

        await fetch(`${env}/api/capex/createPart`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('part created ==> ', JSON.parse(result))
            alert(partdesc + ' has been added to the part list!')
            setPartDesc('')
            setPartNumber('')
            setSearchWord('')
          
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    const getDepartments = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/getdepartments`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('all departments ==> ', JSON.parse(result))
            isDepartments(JSON.parse(result))
        })
        .catch(error => {
            console.log('error', error)
            // isDepartments(['An error occured.'])
        });
    } 
  
    let userSelect = users?.map((data) => (
        <option value={data.ID} permission={data.Permission} departments={data.Department}>{data.Name} {data.Banned == '1'? ' [Banned]' : ''}</option>
    ))

  
    
    const handlePillClick = (lastTwoDigits) => {
        setApproveList((prevList) => ({
          ...prevList,
          [lastTwoDigits]: !prevList[lastTwoDigits]
        }));
        console.log('approved list ==> ',approveList)
      //   isApproveList(approveList)
    };

    const Pills = ({ isActive, label, onClick }) => {
        return (
          <p
            className={`pills ${isActive ? 'active' : ''}`}
            onClick={onClick}
          >
            {label}
          </p>
        );
    };
    

    let departmentsCard = departments.map((data) => {

       
        return(
        
            <Pills
                key={data?.LastTwoDigits} // Ensure each component has a unique key
                isActive={approveList[data?.LastTwoDigits]}
                label={data?.LastTwoDigits + ' / ' + data?.DepartmentDescription}
                onClick={() => {
                    handlePillClick(data?.LastTwoDigits)
                    isNewSelection(true)
                }}
            />
        )
    })

    useMemo(() => {
        getDepartments()
    },[])

  
    return (
        <div className="row h-100" >

            <div className="modal" id="editCard" tabIndex="-1" data-bs-keyboard="false">
                <div className="modal-dialog modal-fullscreen animate__animated animate__zoomInUp animate__faster">
                    
                    <div className="row w-100 h-100" style={{height:'70%'}}>
                        <div className="col-12 center">
                            <div className='details-card'>
                                <br/>
                                <div className="w-100 h-100" style={{overflowY:'auto'}}>
                                    <div className="blue-card">
                                        <div className="d-flex">
                                            <h1 style={{width:'100%'}}>Parts</h1>
                                           
                                        </div>
                                        
                                     
                                    </div>

                                    <br/><br/>

                                    <div className="col-12" style={{margin:'auto'}}>

                                        <div className="blue-card">
                                            <div className="card-inside" style={{backgroundColor:'white'}}> 
                                                <div className="row" >
                                                    <div className="col-4 text-center"><b>Part Number</b></div>
                                                    <div className="col-4 text-center"><b>Search Word</b></div>
                                                    <div className="col-4 text-center"><b>Description</b></div>
                                                </div>
                                            </div>

                                            {/* {renderUsers} */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
          
            <div className="col-12 h-100">
                <div className={`custom-card animate__animated animate__fadeIn`} style={{overflowY:'auto'}}>
                    <div className="row">
                        <div className="col-12">
                           
                            <div 
                                className="w-50 text-center" 
                                style={{
                                    backgroundColor: 'whitesmoke',
                                    borderRadius: 12,
                                    padding: 15,
                                    margin: 'auto'
                                }}
                            >
                                <img className="mb-3" style={{width:125,height:125,borderRadius:'100%'}} src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" />
                                <h6>{profile.name}</h6>
                            </div>
                            
                        </div>
                    </div>

                    <br/> 

                    <div className="w-50 mb-5" style={{margin:'auto'}}>
                        <div className="w-100" style={{backgroundColor:'whitesmoke',borderRadius:12,padding:15}}>
                            <h6 className="mb-3">Change Roles</h6>

                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">User</span>
                                <div className="form-floating">
                                    <select 
                                        className="form-select" 
                                        value={roleUser}
                                        defaultValue='none' 
                                        onChange={(e) => {
                                            const selectedIndex = e.target.selectedIndex;
                                            const selectedOption = e.target[selectedIndex];
                                            const customDataValue = selectedOption.getAttribute('permission');
                                            const customDepartments = selectedOption.getAttribute('departments')?.split(',');
                                            const departments = {}

                                            console.log('selected departments ==> ',customDepartments)
                                            if(customDepartments && customDepartments.length > 0){
                                                for(let i = 0; i < customDepartments.length; i++){
                                                    console.log('select departments ==>',customDepartments[i])
                                                    departments[customDepartments[i]] = true
                                                }
                                            }
                                            console.log('departments pre set ==> ',departments)

                                            isRoleUser(e.target.value)
                                            isRoleValue(customDataValue)
                                            isRoleValueOG(customDataValue)
                                            setApproveList({})
                                            setApproveList(departments)
                                            console.log(e.target.value)
                                        }
                                    }>
                                        <option selected value="none" permission="none">None</option>
                                        {userSelect}
                                    </select>
                                    <label for="departmentSelect">Select a user</label>
                                </div>
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Role</span>
                                <div className="form-floating">
                                    <select 
                                        className="form-select" 
                                        value={roleValue}
                                        onChange={(e) => {
                                         
                                            isRoleValue(e.target.value)
                                            console.log(roleValue + ' vs ' + roleValueOG)
                                            setApproveList({})
                                        }}
                                    >
                                        <option selected value="none">None</option>
                                        <option value="basic" >Basic</option>
                                        <option value="approver_level1">Approver Level 1</option>
                                        <option value="approver_level2">Approver Level 2</option>
                                        <option value="approver_level3">Approver Level 3</option>
                                        <option value="approver_level4">Approver Level 4</option>
                                        <option value="admin">Admin</option>
                                        <option value="ban">Ban</option>
                                    </select>
                                    <label for="departmentSelect">Select a role</label>
                                </div>
                            </div>

                            {(roleValue == 'approver_level1') &&
                                <div className=" d-flex flex-wrap pill-container">
                                {departmentsCard}
                                </div>
                            }
                            

                            <div className="w-100 text-center">
                                <button 
                                    type="button" 
                                    class={`btn btn-primary w-25 ${newselection || (roleUser !== 'none' && roleValue !== roleValueOG)? '' : 'disabled' } `}
                                    onClick={() => changeRole()}
                                    style={{borderRadius:100}}
                                >
                                    Update
                                </button>
                            </div>
                            
                        </div>
                    </div>

                    <br/> 

                    <div className="w-50 mb-5" style={{margin:'auto'}}>
                        <div className="w-100" style={{backgroundColor:'whitesmoke',borderRadius:12,padding:15,position:'relative'}}>
                            <h6 className="mb-3">Create Product</h6>
                            {/* <span 
                                class="material-symbols-outlined text-center active editButton"
                                data-bs-toggle="modal" data-bs-target="#editCard"
                            >edit</span> */}


                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Part Number</span>
                                <input 
                                    placeholder='Part number...'
                                    className="form-control"
                                    value={partnumber}
                                    onChange={(e) => {
                                        let val = e.target.value
                                        setPartNumber(val)
                                    }}
                                />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Part Description</span>
                               
                                <input 
                                    value={partdesc}
                                    placeholder='Part description...'                                 
                                    className="form-control"
                                    onChange={(e) => {
                                        let val = e.target.value
                                        setPartDesc(val)
                                    }}
                                />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Search Word</span>
                               
                                <input 
                                    value={searchword}
                                    placeholder='search word...'                                 
                                    className="form-control"
                                    onChange={(e) => {
                                        let val = e.target.value
                                        setSearchWord(val)
                                    }}
                                />
                            </div>

                         

                            <div className="w-100 text-center">
                                <button 
                                    type="button" 
                                    class={`btn btn-primary w-25 ${partdesc.length > 0 && searchword.length > 0 && partnumber.length > 0? '' : 'disabled' } `}
                                    onClick={() => createPart()}
                                    style={{borderRadius:100}}
                                >
                                    Create
                                </button>
                            </div>
                            
                        </div>
                    </div>
                   
                    <div className="d-flex text-center w-50" style={{margin:'auto',marginTop:'2%'}}>
                        <div class="d-flex settingsCard">
                            <p class="mb-0 w-100 text-center"><b>Version Number -</b> 0.9 Testing / d</p>
                        </div>
                    </div>
              
                </div>
            </div>
           
        </div>
    )
}

export default Settings