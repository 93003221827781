import React, { useState, useEffect, useRef, useContext } from "react"
import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import FilterCard from "../components/Cards/FilterCard";
import RequestCard from "../components/Cards/RequestCard"
import { ProviderContext } from "../App";

import StatCard from "../components/Cards/StatCard";
import StatCardRound from "../components/Cards/StatCardRound";

import BottomNavbarApprover from "../components/BottomNavbarApprover";

import { Player, Controls } from '@lottiefiles/react-lottie-player';





/* eslint-disable padded-blocks */
function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

let formatText = (text) => { 
    if(text.toLowerCase() == 'pending') return ' Approval'
    else return ''
}

let Approver = (props) => {
    const modalRef = useRef(null);
    const [data, isData] = useState([])
    const [allrequests, isAllRequests] = useState([])
    const [count, isCount] = useState({
        approved: false,
        denied: false
    })
    const [dataFromMail, isDataFromMail] = useState({})
    const [displayModal, isDisplayModal] = useState(false)
    const departments = props.Departments

    const [currRequest, isCurrRequest] = useState(null)
    const [requestsolved, isRequestSolved] = useState(false)
    const [resolution, isResolution] = useState(null)
    const {access,profile,env} = useContext(ProviderContext)
    const level = parseInt(access[access.length - 1])
    const [menu, isMenu] = useState('Requests')
    const [submenu, isSubmenu] = useState('Approved')
    const [currspent, isCurrSpent] = useState(0)
    const [nextapprover, isNextApprover] = useState(null)

    const [selected, isSelected] = useState('Requests')
    const [downloadQuote, setDownloadQuote] = useState(null)
    const [downloadFormat, setDownloaFormat] = useState(null)
    
    const [stats, isStats] = useState({
        total:0,
        approved:0,
        denied:0,
        approved_money:0
    })

    console.log('level ===> ',level)

    let formatMoney = (p) => {
        if(p > 999 && p < 9999){
            let str = '' + p + ''
            return str.substring(0,1) + 'k'
        }
        else if(p > 9999 && p < 99999){
            let str = '' + p + ''
            return str.substring(0,2) + 'k'
        }
        else if(p > 99999 && p < 999999){
            let str = '' + p + ''
            return str.substring(0,3) + 'k'
        }
        else if(p > 999999 && p < 9999999){
            let str = '' + p + ''
            return str.substring(0,1) + '.' + str.substring(1,2) + 'm'
        }
        else{
            let str = '' + p + ''
            return str.substring(0,2) + '.' + str.substring(2,3) + 'm'
        }
    }

    let getRequests = async () => {
        
        console.log('check for new requests...',departments)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getRequestsApprover?level=${level}&departments=${departments}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('all requests ==> ', JSON.parse(result))
            let res = JSON.parse(result)
          
            console.log('data for user with level 2 ===> ',data)
            isData(res)

            // setTimeout(getRequests, 5000)
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    let getRequestFromMail = async (id) => {
        
        console.log('check for new requests...')
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getRequestFromMail?id=${id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('all requests ==> ', JSON.parse(result))
            let res = JSON.parse(result)
          
            console.log(data)
            isDataFromMail(res[0])
            isCurrRequest(res[0])

           
            setTimeout(() => {
                 // eslint-disable-next-line
                $('#detailsCard').modal('show')
            }, 450);
        //    alert('hello?')

            setTimeout(getRequests, 5000)
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    let getCurrentSpending = async () => {
        
        console.log('check for this project spending ===> ',currRequest?.BudgetCode)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getCurrentSpending?budgetcode=${currRequest?.BudgetCode}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('amount spent ==> ', JSON.parse(result))
            let res = JSON.parse(result)
          
            console.log(res)

            isCurrSpent(parseInt(res[0].Spent))

            console.log('spent amount for this budget ===> ', currspent)
          
           
    
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    let getStats = async () => {
        
        console.log('check for new requests...')
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getStats?level=${level}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('stats ==> ', JSON.parse(result))
            
            let data = JSON.parse(result)
            let total = data.length - 1
            let approved = 0
            let denied = 0
            let approved_money = 0

            console.log(data.length)

            
            for(data of data){
                if(data.Status == 'approved') approved_money += parseInt(data.EstimatedValue)
                
                if(data[`Approval${level}`]  == 'approved' && data[`Approval${level}ByEmail`] == profile.username){
                    approved += 1
                }
                else if(data[`Approval${level}`]== 'denied'  && data[`Approval${level}ByEmail`] == profile.username) denied += 1
            }

            console.log('money approved ==> ',approved_money)
            isStats({
                total: total,
                approved: approved,
                denied: denied,
                approved_money: approved_money,
            })

            console.log('data to store ===> ',data)
            isAllRequests(JSON.parse(result))

           
            

        })
        .catch(error => {
            console.log('error', error)
        });

    }

    const getNextApprover = async (level) => {
       
       
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getNextApprover?level=${level}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('first approver ==> ', JSON.parse(result))
            const res = JSON.parse(result)

            if(res.length > 0){
                isNextApprover({
                    ID: res[0].ID,
                    Name: res[0].Name
                })
            }
            else{
                isNextApprover({
                    ID: 'khadim.mbow@heathus.com',
                    Name: 'Khadim Mbow'
                })
            }
        })
        .catch(error => {
            console.log('error', error)
        });
    } 

    let solveRequest = async (id,name,status,steps,email) => {
        
        console.log('solve request with ' + status + ' status', level,id,name,status,steps)

        let spent = currspent + parseInt(currRequest?.EstimatedValue)
        
        console.log('total spent ===> ',spent)
        
        // var requestOptions = {
        //     method: 'POST',
        //     redirect: 'follow'
        // };
          
        // await fetch(`${env}/api/capex/solveRequest?level=${level}&ID=${id}&name=${profile.name}&status=${status}&steps=${steps}&email=${profile.username}&spent=${spent}&budgetcode=${currRequest?.BudgetCode}`, requestOptions)
        // .then(response => response.text())
        // .then(result => {
        //     console.log('all requests ==> ', JSON.parse(result))

        //     let requiredApproval = currRequest?.ApprovalRequired
        //     let left = requiredApproval - level
            
        //     isRequestSolved(true)
        //     isResolution(status)

        //     // subject, body, address, displayName
        //     if(level == 1){
        //         sendEmail(
        //             `Approval Status for your request with ID - ${currRequest.ID}`, 
        //             `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}. There is ${left == 0 || status == 'denied'? 'no' : left} approvals left in the process. For any further inquiries or questions, please contact them.`,
        //             currRequest.OriginatorEmail,
        //             currRequest.OriginatorName
        //         )
        //     } 
        //     else if(level == 2){
        //         sendEmail(
        //             `Approval Status for your request with ID - ${currRequest.ID}`, 
        //             `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}. There is ${left == 0 || status == 'denied'? 'no' : left} approvals left in the process. For any further inquiries or questions, please contact them.`,
        //             currRequest.OriginatorEmail,
        //             currRequest.OriginatorName
        //         )
        //         if(currRequest.ApprovalRequired > 2){
        //             sendEmail(
        //                 `Request with ID - ${currRequest.CapexID} by ${currRequest.OriginatorName.split(' ')[0]} Needs 2nd Approval`, 
        //                 `Hi, ${currRequest.OriginatorName}, has requested item ${currRequest.ItemDescription} with an estimated budget of ${currRequest.EstimatedValue}$. It has already been approved by the department head. You can review this Capex by clicking the following link: http://lemon-glacier-0a9730b10.4.azurestaticapps.net/?id=${currRequest.CapexID}`,
        //                 nextapprover.ID,
        //                 profile.name
        //             )
        //         }
        //     } 
        //     else if(level == 3){
        //         sendEmail(
        //             `Approval Status for your request with ID - ${currRequest.ID}`, 
        //             `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}. There is ${left == 0 || status == 'denied'? 'no' : left} approvals left in the process. For any further inquiries or questions, please contact them.`,
        //             currRequest.OriginatorEmail,
        //             currRequest.OriginatorName
        //         )
        //         if(currRequest.ApprovalRequired > 3){
        //             sendEmail(
        //                 `Request with ID - ${currRequest.CapexID} by ${currRequest.OriginatorName.split(' ')[0]} Needs 2nd Approval`, 
        //                 `Hi, ${currRequest.OriginatorName}, has requested item ${currRequest.ItemDescription} with an estimated budget of ${currRequest.EstimatedValue}$. It has already been approved by the department head. You can review this Capex by clicking the following link: http://lemon-glacier-0a9730b10.4.azurestaticapps.net/?id=${currRequest.CapexID}`,
        //                 nextapprover.ID,
        //                 profile.name
        //             )
        //         }
        //     } 
        //     else{
        //         sendEmail(
        //             `Approval Status for your request with ID - ${currRequest.ID}`, 
        //             `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}. For any further inquiries or questions, please contact them.`,
        //             currRequest.OriginatorEmail,
        //             currRequest.OriginatorName
        //         )
        //     } 
            

        //     setTimeout(() => {
        //         isRequestSolved(false)
        //         isResolution(null)
        //         // eslint-disable-next-line
        //         $('#detailsCard').modal('hide')
        //     }, 8000);
        // })
        // .catch(error => {
        //     console.log('error', error)
        // });

    }

    let sendEmail = async (subject, body, address, displayName) => {
        
        console.log('email ===> ', subject, body, address, displayName)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/sendEmail?subject=${subject}&body=${body}&address=${address}&displayName=${displayName}`, requestOptions)
        .then(response => response.text())
        .then(result => {
           console.log('email sent !')
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    function dataURItoFile(dataURI) {
        // Extract the Base64 content
        if(dataURI && dataURI.length > 15){
            const base64Content = dataURI.split(',')[1];
            const fileType = dataURI.split('/')[1].split(';')[0]
          
            // Decode Base64 string into binary data
            const binaryString = atob(base64Content);
          
            // Create a typed array from binary string
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              byteArray[i] = binaryString.charCodeAt(i);
            }
          
            // Create Blob from the typed array with appropriate MIME type
            const blob = new Blob([byteArray], { type: dataURI.split(';')[0] });
          
            // Optionally, create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);
             
          
          
            console.log('converted blob file ===> ',fileURL)
          
            setDownloadQuote(fileURL)
            setDownloaFormat(fileType == 'vnd.openxmlformats-officedocument.wordprocessingml.document'? 'docx' : 'pdf')
            // return blob; // or return the Blob object if needed
        }
     
    }

    let renderCards = data.map((data) => (
        <RequestCard 
            Request={data.ItemDescription? data.ItemDescription : 'Unidentified name'}  
            Requestor={data.OriginatorName.split(' ')[0]}
            Status = {data.Status? capitalizeFirstLetter(data.Status) + formatText(data.Status) : 'Pending Approval'}
            onClick={() => {
            
                
                console.log('current data ===> ',data)
                let int = parseInt(data.EstimatedValue)
                isCurrRequest(data)
                console.log(typeof(int))
                getCurrentSpending()
                console.log('steps left for approval ===> ',data.ApprovalRequired)

                if(data?.QuoteValue?.length > 10){
                    console.log('convert to blob')
                    dataURItoFile(data?.QuoteValue)
                }
            }}
        />
    ))

    let renderApproved = allrequests.map((data) => {
        if (data[`Approval${level}`] == 'approved' && data[`Approval${level}ByEmail`] == profile.username){
            return (
                <RequestCard 
                    Request={data.ItemDescription? data.ItemDescription : 'Unidentified name'}  
                    Requestor={data.OriginatorName.split(' ')[0]}
                    Status = 'Approved'
                    onClick={() => {
                        console.log('current data ===> ',data)
                        let int = parseInt(data.EstimatedValue)
                        isCurrRequest(data)
                        console.log(typeof(int))
                        getCurrentSpending()
                        console.log('steps left for approval ===> ',data.ApprovalRequired)
                    }}
                />
            )
        } 
        
    })

    let renderDenied = allrequests.map((data) => {
        if(data[`Approval${level}`] == 'denied' && data[`Approval${level}ByEmail`] == profile.username){
            return (
                <RequestCard 
                    Request={data.ItemDescription? data.ItemDescription : 'Unidentified name'}  
                    Requestor={data.OriginatorName.split(' ')[0]}
                    Status = 'Denied'
                    onClick={() => {
                        console.log('current data ===> ',data)
                        let int = parseInt(data.EstimatedValue)
                        isCurrRequest(data)
                        console.log(typeof(int))
                        getCurrentSpending()
                        console.log('steps left for approval ===> ',data.ApprovalRequired)
                    }}
                />
            )
        } 
        
    })

    const Menu = (props) => (
        <div className={`selector ${props.Title == menu? 'active' : ''}`} onClick={props.onClick}>{props.Title}</div>
    )

    const Submenu = (props) => (
        <div className={`selector other ${props.Title == submenu? 'active' : ''}`} onClick={props.onClick}>{props.Title}</div>
    )

    useEffect(() => {
        console.log('the access level is ===>', access[access.length - 1])
        console.log('user profile ===> ',profile)
        getRequests()
        getStats()
        getNextApprover(level + 1)

        const url = new URL(window.location.href);
        const paramValue = url.searchParams.get('id');

        if(paramValue && paramValue.length > 1){
            console.log('param values ===> ',paramValue)
            getRequestFromMail(paramValue)
        }
    },[])

  
    return (
        <div className="row h-100" >

            <div className="modal" id="detailsCard" tabIndex="-1" data-bs-keyboard="false" >
                <div className="modal-dialog modal-fullscreen animate__animated animate__zoomInUp animate__faster">
                    <div className="row w-100 h-100" style={{height:'70%'}}>
                        <div className="col-12 center">
                            <div className='details-card' ref={modalRef}>

                                <span 
                                    className="material-symbols-outlined btn-close" 
                                    data-bs-dismiss="modal" aria-label="Close"
                                    style={{
                                        position:'absolute',
                                        right:'3%'
                                    }}
                                >close</span>

                                <br/>
                                {!requestsolved?
                                    (
                                        <div className="w-100 h-100" style={{overflowY:'auto',overflowX:'hidden'}}>
                                            <div className="blue-card">
                                                <div className="d-flex">
                                                    <h5 className="mb-0" style={{width:'100%'}}>[{currRequest?.ItemDescription}]  | Request by - {currRequest?.OriginatorName}</h5>
                                                </div>
                                                
                                            </div>
        
                                            <br/>
        
                                            
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row blue-card g-2 needs-validation testform" novalidate>
                                                        <h5>Purchase</h5>
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Asset Category</span>
                                                                <input className="form-control" value={currRequest?.AssetCategory} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3 flex-nowrap">
                                                                <span className="input-group-text" id="basic-addon1">Quantity</span>
                                                                <input className="form-control" value={currRequest?.Quantity} disabled  />
                                                            </div>
                                                            
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text " id="basic-addon1">Justification of Purchase</span>
                                                                <input className="form-control" value={currRequest?.JustificationOfPurchase} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Ship To Address</span>
                                                                <input className="form-control" value={currRequest?.ShipToAddress} disabled  />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                                <input className="form-control" value={currRequest?.AddressTo} disabled  />
                                                            </div>
                                                        </div>
                                                        
        
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">CAPEX or PURCHASE REQ</span>
                                                                    <input className="form-control" value={currRequest?.ID} disabled  />
                                                                </div>
                                                                
                                                                <div className="input-group mb-3" style={{position:'relative'}}>
                                                                    <span className="input-group-text" id="basic-addon1">Part Number</span>
                                                                    <input className="form-control" value={currRequest?.PartNumber} disabled  />
                                                                </div>
                                                                
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">Item Description</span>
                                                                    <input className="form-control" value={currRequest?.ItemDescription} disabled  />
                                                                </div>
        
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">Location</span>
                                                                    <input className="form-control" value={currRequest?.AddressFrom} disabled  />
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                                    <input className="form-control" value={currRequest?.AddressTo} disabled  />
                                                                </div>
                                                            </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
        
                                            <br/>
        
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="blue-card g-2 row">
                                                        <h5>Cost</h5>
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Vendor</span>
                                                                <input className="form-control" value={currRequest?.Vendor} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Quote #</span>
                                                                <input className="form-control" value={currRequest?.Quote} disabled  />
                                                            </div>

                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Quote Attachment</span>

                                                                {downloadQuote?
                                                                    (
                                                                        <>
                                                                            <input className="form-control" disabled />
                                                                            <a className='quoteDownload' href={downloadQuote} download={currRequest?.ID + `_quote.${downloadFormat}`}>Download</ a>
                                                                        </>
                                                                        
                                                                            
                                                                    
                                                                    )

                                                                    :

                                                                    (
                                                                        <input className="form-control" value='There is no attached quote' disabled  />
                                                                    )
                                                                }
                                                                
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Department</span>
                                                                <input className="form-control" value={currRequest?.Department} disabled  />
                                                            </div>
        
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Budgeted</span>
                                                                <input className="form-control" value={currRequest?.Budgeted? 'Yes' : 'No'} disabled  />
                                                            </div>
        
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Unbudgeted ROI</span>
                                                                <input className="form-control" value={currRequest?.Unbudgeted? currRequest?.Unbudgeted : 'N/A'} disabled  />
                                                            </div>
                                                        </div>
                                                        
        
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Quote Date</span>
                                                                <input className="form-control" value={currRequest?.QuoteDate} disabled  />
                                                                
                                                            </div>
                                                                
                                                            <div className="input-group mb-3" style={{position:'relative'}}>
                                                                <span className="input-group-text" id="basic-addon1">Estimated Value</span>
                                                                <input className="form-control" value={currRequest?.EstimatedValue} disabled  />
                                                                <span className="input-group-text">$</span>
                                                            
                                                            </div>
                                                            
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Project Code</span>
                                                                <input className="form-control" value={currRequest?.ProjectCode} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Budget Code</span>
                                                                <input className="form-control" value={currRequest?.BudgetCode} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Budget Amount</span>
                                                                <input className="form-control" value={currRequest?.BudgetAmount} disabled  />
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <br/> 
        
                                            <div class="row">
        
                                                <div class="col-12">
                                                    <div className="blue-card row g-2">
                                                        <h5>Contact</h5>
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Originator</span>
                                                                <input className="form-control" value={currRequest?.OriginatorName} disabled  />
                                                            </div>
        
        
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Contact Phone Number</span>
                                                                <input className="form-control" value={currRequest?.Phone} disabled  />
                                                            </div>
        
        
                                                        
                                                        </div>
        
                                                        <div className="col-xl-6 col-sm-12">
                                                        
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Request Date</span>
                                                                <input className="form-control" value={currRequest?.DateRequest} disabled  />
                                                            </div>
        
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Date Needed</span>
                                                                <input className="form-control" value={currRequest?.DateNeeded} disabled  />
                                                            
                                                            </div>
                                                        </div>
                                                        
        
                                                    
                                                    </div>
                                                </div>
        
        
                                            </div>
        
                                            <br/>

                                            {menu == 'Requests' &&
                                                <div className="row" style={{marginBottom:'2%'}}>
                                                    <div className="col-12">
                                                        <div className="blue-card row g-2">
                                                            
                                                            <div className="col-5">
                                                                <div 
                                                                    style={{borderRadius:100}}
                                                                    className="btn btn-danger w-100"
                                                                    onClick={() => solveRequest(currRequest?.ID,currRequest?.OriginatorName,'denied',currRequest?.ApprovalRequired,currRequest?.OriginatorEmail)}
                                                                >
                                                                    Deny
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-2" />
                                                            
                                                            <div className="col-5">
                                                                <div 
                                                                    style={{borderRadius:100}} 
                                                                    className="btn btn-primary w-100"
                                                                    //level,id,name,status,steps,email
                                                                    onClick={() => solveRequest(currRequest?.ID,currRequest?.OriginatorName,'approved',currRequest?.ApprovalRequired,currRequest?.OriginatorEmail)}
                                                                    >
                                                                        Approve
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        
                                    )

                                    :

                                    (
                                        <div className="text-center">
                                            <Player
                                                autoplay
                                                loop
                                                src={resolution == 'approved'? 'https://lottie.host/78f35b4a-14fd-4afe-8abe-2fb8073e8b9c/UEK55G0hRx.json' : 'https://lottie.host/a1fce545-f060-4366-990a-bc104d793d99/LokY6kJs0B.json'}
                                                style={{ width: '25%',justifyContent:'center', alignItems:'center',marginTop:'1%'}}
                                            />

                                            <p>{currRequest?.OriginatorName.split(' ')[0]} will be notified his request has been {resolution}, thanks!</p>
                                        </div>
                                       
                                    )
                                }   
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
          
            <div className="col-1 hide" />

            <div className="col-xl-10 col-12 h-100">
                <div 
                    className='custom-card approver w-100 animate__animated animate__fadeIn'
                    
                >
                    <div className="w-100" style={{height:'95%',overflowY:'auto',overflowX:'hidden'}}>
                        <div className="d-flex hide advanced">
                         
                            <Menu Title='Requests' onClick={() => isMenu('Requests')} />
                            <Menu Title='History' onClick={() => isMenu('History')} />
                            <Menu Title='Insights' onClick={() => isMenu('Insights')} />
                        </div>

                       

                        <h1 className="mb-0 approverTitle">Hi, {profile?.name.split(' ')[0]}</h1>
                        <small style={{marginLeft:'1.2%'}}>
                            {menu == 'Requests' &&
                                <>
                                    {data.length > 0?
                                        (<>Here are your unresolved requests.</>)
                                        :
                                        (<>There are no unresolved requests.</>)    
                                    }
                                </>
                                
                            }
                            {menu == 'History' &&
                                
                                <>
                                {allrequests.length > 0?
                                    (<>Here is the history of all your requests.</>)
                                    :
                                    (<>There is no recorded history yet.</>)    
                                }
                            </>
                            }
                            {menu == 'Insights' &&
                                <>Here are your Capex insights this year.</>
                            }
                        </small>
                        <br/> <br/>
                        <div className="row w-100" style={{margin:'auto'}}>
                           
                            {menu == 'Requests' &&
                                <>
                                    {renderCards}
                                </>
                            }
                           
                            {menu == 'History' &&
                                <div className="col-12">
                                    <div >
                                        <div className="d-flex flex-row w-100 mb-3" style={{justifyContent:'center',alignItems:'center'}}>
                                            <Submenu Title='Approved' onClick={() => isSubmenu('Approved')} />
                                            <Submenu Title='Denied' onClick={() => isSubmenu('Denied')} />
                                        </div>
                                        <div className="row" style={{backgroundColor:'whitesmoke',borderRadius:12,padding:10, overflow:'hidden',margin:'auto'}}>
                                            <>
                                                {submenu == 'Approved'?
                                                    (

                                                        <>
                                                            {renderApproved.length > 0?
                                                                (
                                                                    <>{renderApproved}</>
                                                                )
                                                                :
                                                                (
                                                                    <p>There is no recorded approved requests</p>
                                                                )
                                                            }
                                                        </>
                                                        
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            {renderDenied.length > 0?
                                                                (
                                                                    <>{renderDenied}</>
                                                                )
                                                                :
                                                                (
                                                                    <p>There is no recorded denied requests</p>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                            </>
                                        </div>
                                    </div>

                                 
                                </div>
                            }

                            {menu == 'Insights' &&
                                <div className="col-12 h-100">
                                    <div className="row h-100">
                                        
                                     
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100" >
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Total Requests</small>
                                                <h1 className="reallyBigNumber">{stats.total}</h1>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100" >
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Approved Requests</small>
                                                <h1 className="reallyBigNumber">{stats.approved}</h1>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100" >
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Approved Budget</small>
                                                <h1 className="reallyBigNumber">${formatMoney(stats.approved_money)}</h1>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100">
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Denied Requests</small>
                                                <h1 className="reallyBigNumber">{stats.denied}</h1>
                                            </div>
                                        </div>

                                       

                                       
                                    </div>
                                </div>
                            }
                            
                        </div>
                      
                    </div>
                </div>

                <BottomNavbarApprover 
                    onClick1={() => {
                        isSelected('Requests')
                        isMenu('Requests')
                    }}
                    onClick2={() => {
                        isSelected('History')
                        isMenu('History')
                    }}
                    onClick3={() => {
                        isSelected('Insights')
                        isMenu('Insights')
                    }}
                    Selected = {selected}
                />
            </div>

            <div className="col-1 hide" />
            {/* <div className="col-2" /> */}
           
        </div>
    )
}

export default Approver