import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import StatCard from "../../components/Cards/StatCard";
import StatCardRound from "../../components/Cards/StatCardRound";



let Home = (props) => {
    const statsRequest = props.StatsRequest
    const spentMonthly = props.SpentMonthly
    const perDepartment = props.PerDepartment
    const users = props.Users
    const loaded = props.Loaded

    const databudgetPerMonth = []

    for(let x in spentMonthly) databudgetPerMonth.push(spentMonthly[x])

    console.log('month data ===> ' , spentMonthly)
    console.log('per department ==> ', perDepartment)
    
    const budgetPerMonth = {
        labels: ['Oct','Nov','Dec','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul','Aug','Sep'],
        datasets: [{
            label: 'Amount Spent Per Month',
            data: databudgetPerMonth,
    
        }]
    }
    
    const budgetPerDepartment = {
        labels: perDepartment.label,
        datasets: [{
            label: 'Amount Spent',
            data: perDepartment.data,
            backgroundColor: ['#42A5F5', '#EF5350','#00ACC1', '#AB47BC','#FFEE58','#8D6E63','#00BCD4','#8BC34A','#E91E63'],
        }]
    }

    console.log('stats request ===> ', statsRequest)

    let formatMoney = (p) => {
        if(p > 9999 && p < 99999){
            let str = '' + p + ''
            return str.substring(0,2) + 'k'
        }
        else if(p > 99999 && p < 999999){
            let str = '' + p + ''
            return str.substring(0,3) + 'k'
        }
        else if(p > 999999 && p < 9999999){
            let str = '' + p + ''
            return str.substring(0,1) + '.' + str.substring(1,2) + 'm'
        }
        else{
            let str = '' + p + ''
            return str.substring(0,2) + '.' + str.substring(2,3) + 'm'
        }
    }

    let renderUsers = users?.map((data) => {
        let status = () => {
            if(!data.Permission) return 'Unassigned'
            else if(data.Permission == 'admin') return 'Admin'
            else if(data.Permission == 'basic') return 'Basic'
            else return 'Approver Lvl ' + data.Permission[data.Permission.length - 1]
        }
        return(
            <div className="card-inside">
                <div className="row">
                    <div className="col-3 text-center">{data.Name}</div>
                    <div className="col-3 text-center">{data.ID}</div>
                    <div className="col-3 text-center">{data.Joined.substring(0,10)}</div>
                    <div className="col-3 text-center">{status()}</div>
                </div>
            </div>
        )
        
    })

    return(
        <div 
            className={`custom-card animate__animated animate__fadeIn`}
            
        >
            {loaded?
                (
                    <div className="w-100 animate__animated animate__fadeIn" style={{height:'95%',overflowY:'auto',overflowX:'hidden'}}>

                        <div className="row">
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
        
                                <StatCardRound
                                    Value = '$1.5m'
                                    Title = 'Total Budget' 
                                    BgColor = 'rgb(25, 118, 210)'
                                />
                            </div>
        
                            
        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCardRound
                                    Value = {'$' + formatMoney(statsRequest?.spent)}
                                    Title = 'Estimated Spent' 
                                    BgColor = 'rgb(25, 118, 210)'
                                />
                            </div>
        
        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCard
                                    Value = {statsRequest?.total}
                                    Title = 'Total Requests' 
                                    BgColor = '#1976D2'
                                />
                            </div>
                        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCard
                                    Value = {statsRequest?.approved}
                                    Title = 'Approved Requests' 
                                    BgColor = '#388E3C'
                                />
                            </div>
        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCard
                                    Value = {statsRequest?.pending}
                                    Title = 'Pending Requests' 
                                    BgColor = '#F57C00'
                                />
                            </div>
        
                            <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                <StatCard
                                    Value = {statsRequest?.rejected}
                                    Title = 'Rejected Requests' 
                                    BgColor = '#D32F2F'
                                />
                            </div>
                            
                        </div>
        
                        <br/> 
        
                        <div className="row">
                        
        
                            <div className="col-xl-5 col-12" style={{margin:'auto'}}>
                                <Doughnut 
        
                                    data={budgetPerDepartment}
                                    width={'50%'}
                                    height={'25%'}
                                    options={{ 
                                        maintainAspectRatio: true ,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'left'
                                            }
                                        }
                                    }}
                                
                                />
                            </div>
        
                            <div className="col-xl-7 col-12" style={{margin:'auto'}}>
                                <Chart
                                    type='bar'
                                    data={budgetPerMonth}
                                    height={'100%'}
                                    options={{ maintainAspectRatio: true }}
                                />
                            </div>
                        </div>
        
                        <br/>
        
                        <div className="row">
        
                            <div className="col-12" style={{margin:'auto'}}>
        
                                <div className="user-card">
                                    <h6 className="mb-3">Registered Users</h6>
                                    <div className="card-inside">
                                        <div className="row" >
                                            <div className="col-3 text-center"><b>Name</b></div>
                                            <div className="col-3 text-center"><b>Email</b></div>
                                            <div className="col-3 text-center"><b>Joined</b></div>
                                            <div className="col-3 text-center"><b>Status</b></div>
                                        </div>
                                    </div>
        
                                    {renderUsers}
                                </div>
                            </div>
        
                        </div>
                    
        
                    
                    </div>
                )

                :

                (
                    <div className="w-100" style={{height:'95%',overflowY:'hidden',overflowX:'hidden'}}>

                        <div className="row">
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="d-flex statCardRound skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="d-flex statCardRound skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                        
                            <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                <div className="statCard skeleton-box">
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
                            
                        </div>
        
                        <br/> <br/><br/> <br/>
        
                        <div className="row">
                        
        
                            <div className="col-xl-5 col-12" style={{margin:'auto',justifyContent:'center',alignItems:'center'}}>
                                <div className=" skeleton-box" style={{width:'50%',height:300,borderRadius:'100%',marginLeft:'35%'}}>
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
        
                            <div className="col-xl-7 col-12" style={{margin:'auto'}}>
                                <div className=" skeleton-box" style={{width:'100%',borderRadius:24,height:400}}>
                                    <div class="skeleton-animation"></div>
                                </div>
                            </div>
                        </div>
        
                       
                    
        
                    
                    </div>
                )
            }
           
        </div>
    )
   
}

export default Home