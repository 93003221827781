const RequestCard = (props) => {
    
    let statusIndicator = () => {
        if(props.Status == 'Approved') return 'check_circle'
        else if (props.Status == 'Pending Approval') return 'hourglass_top'
        else return 'cancel'
    }
    
    return(
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">                            
            <div className={`d-flex requestCard animate__animated animate__fadeInDown`} onClick={props.onClick} data-bs-toggle="modal" data-bs-target="#detailsCard"> 
                <div className="sideCard">
                    <p className="ellipsis"><b>Request -</b> {props.Request}</p>
                    <p><b>Requested by -</b> {props.Requestor}</p>
                    <p className="mb-0"><b>Status -</b> {props.Status}</p>
                    <span className="material-symbols-outlined requestCardIcon">{statusIndicator()}</span>
                </div>
            </div>
        </div>
    )
}
export default RequestCard