import { useState, useContext, useEffect } from "react";
import NotifCard from "../components/Cards/NotifCard";
import Navbar from "../components/Navbar";
import BottomNavbar from "../components/BottomNavbar";
import { ProviderContext } from "../App";

import Home from "../subroutes/Basic/Home";
import Requests from "../subroutes/Basic/Requests";
import Settings from "../subroutes/Basic/Settings";

let Basic = (props) => {

    const [requestsApproved, isRequestsApproved] = useState([])
    const [data, isData] = useState([])
    const [selected, isSelected] = useState('Dashboard')
    const [loaded, isLoading] = useState(false)
    const {profile,env} = useContext(ProviderContext)

    const [stats, isStats] = useState({
        total:0,
        approved:0,
        denied:0
    })

    console.log('profile ===> ', profile)
    
    let getRequests = async () => {
        
        console.log('check for new requests...')
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getRequests?id=${profile.username}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('all requests ==> ', JSON.parse(result))
            const res = JSON.parse(result)
          

            let total = res.length
            let approved = 0
            let denied = 0

            for(let data of res){
                console.log('go through data ==> ',data)
                if(data.Status == 'approved') approved += 1
                else if(data.Status == 'denied') denied += 1
            }
            console.log('total requests ===> ',total)

            isData(res)
            isRequestsApproved(res)
            isLoading(true)
           
            isStats({
                total: total,
                approved: approved,
                denied: denied
            })

            // setTimeout(getRequests, 5000)
        })
        .catch(error => {
            console.log('error', error)
            isLoading(true)
        });

    }

    useEffect(() => {
        getRequests()
    },[])
   
    return(
        <div className="row h-100" >

            <div className='col-xl-2 col-lg-2 col-md-2 d-flex h-100 mobilecontrol' style={{justifyContent:'center',alignItems:'center'}}>
                <Navbar 
                    onClick1={() => isSelected('Dashboard')}
                    onClick2={() => isSelected('Requests')}
                    onClick3={() => isSelected('Settings')}
                    Selected = {selected}
                />
            </div>
            
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 h-100">
                {selected == 'Dashboard' &&
                    <Home Loaded={loaded} RequestData={data} />
                }

                {selected == 'Requests' &&
                    <Requests Data={data} />
                }

                {selected == 'Settings' &&
                    <Settings Stats={stats}/>
                }

                <BottomNavbar 
                    onClick1={() => isSelected('Dashboard')}
                    onClick2={() => isSelected('Requests')}
                    onClick3={() => isSelected('Settings')}
                    Selected = {selected}
                />
               
            </div>

           
        </div>
    )
   
}

export default Basic